<!--
 * @Author: your name
 * @Date: 2021-01-29 11:27:59
 * @LastEditTime: 2021-12-14 15:43:40
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \aiparkcity-acb\src\components\areaChart\areaChart.vue
-->
<template>
  <div id="areaGraph">
  </div>
</template>
<script>
import { dateFormat } from "@/common/js/public"
export default {
  props: {
    carHourStatics: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  data() {
    return {
      dateList: [],
      dataDetailList: []
    }
  },
  watch: {
    carHourStatics: {
      handler(value) {
        this.dateList = [];
        this.dataDetailList = [];
        let nowDate = new Date();
        for (var i = 0; i < value.length; i++) {
          nowDate.setHours(value[i].hour)
          nowDate.setMinutes(0)
          this.dateList.push(dateFormat(nowDate, 'HH:mm'))
          this.dataDetailList.push(Number(value[i].occupyRatio).toFixed(2))
        }
        this.drawGraph();
      },
      deep: true
    }
  },
  methods: {
    drawGraph() {
      let areaGraph = this.$echarts.init(document.getElementById('areaGraph'));
      let option = {
        // title: {
        //   text: '今日泊位占用趋势'
        // },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '5%',
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          formatter: "时间 : {b} <br/> 占用率 : {c}%"
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.dateList
        },
        yAxis: {
          type: 'value',
          name: '泊位占用率',
          min: 0,
          max: 100,
          splitNumber: 10,
          axisLabel: {
            formatter: function (value) {
              return value + '%'
            }
          }
        },
        series: [{
          data: this.dataDetailList,
          type: 'line',
          areaStyle: {}
        }]
      };
      areaGraph.setOption(option, true);
    }
  },
  mounted() {
    this.drawGraph();
  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
#areaGraph {
  height: 400px;
}
</style>
